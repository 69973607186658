import { useEffect, useState } from "react";
export default (function (durationMS) {
    var _a = useState(null), debounce = _a[0], setDebounce = _a[1];
    var _b = useState(false), debouncing = _b[0], setDebouncing = _b[1];
    var cancelDebounce = function () {
        debounce !== null && clearTimeout(debounce);
    };
    var startDebounce = function (callback) { return setDebounce(function (oldTimeout) {
        if (oldTimeout !== null) {
            clearTimeout(oldTimeout);
        }
        setDebouncing(true);
        return setTimeout(function () {
            callback();
            setDebouncing(false);
        }, durationMS);
    }); };
    useEffect(function () { return cancelDebounce; }, []);
    return {
        debouncing: debouncing,
        startDebounce: startDebounce,
        cancelDebounce: cancelDebounce,
    };
});
