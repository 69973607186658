import { ENDPOINT } from './fetchCommon';
var Req = function (uri, config) {
    if (config === void 0) { config = {}; }
    return (new Promise(function (resolve, reject) {
        fetch("".concat(ENDPOINT).concat(uri), config)
            .then(function (resp) {
            var status = resp.status, statusText = resp.statusText;
            if (status < 200 || status >= 300) {
                var message_1 = "[".concat(status, "] ").concat(statusText);
                resp
                    .json()
                    .then(function (_a) {
                    var serverMsg = _a.message;
                    if (serverMsg !== undefined && serverMsg !== null && serverMsg !== '') {
                        message_1 = serverMsg;
                    }
                    var error = new Error(message_1);
                    reject(error);
                })
                    .catch(function (e) {
                    console.log(e);
                    var error = new Error(message_1);
                    reject(error);
                });
            }
            else {
                resolve(resp); // let caller decide what to do, may not be json
            }
        })
            .catch(function (e) { return reject(e); });
    }));
};
export var ReqJsonToType = function (uri, config) {
    if (config === void 0) { config = {}; }
    return Req(uri, config)
        .then(function (resp) { return resp.json(); })
        .then(function (json) { return json; });
};
export default Req;
