import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import useAuth from "./useAuth";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useFetch from "~/Utils/useFetch";
import enqueueSnackbar from "~/Utils/EnqueueSnackbar";
// export default ({ children }: PropsWithChildren) => {
//     const { user } = useAuth();
//     if (!user) {
//         // user is not authenticated
//         return <Navigate to="/login" />;
//     }
//     return children;
// };
export default (function () {
    var navigate = useNavigate();
    var user = useAuth().user;
    if (user === null) {
        return _jsx(Navigate, { to: "/login" });
    }
    var jwt = user.jwt;
    var error = useFetch('/ping', { message: '' }, {
        headers: {
            'X-JWT': jwt,
        },
    }).error;
    useEffect(function () {
        if ((error === null || error === void 0 ? void 0 : error.status) === 401) {
            enqueueSnackbar(error.message, 'error');
            navigate('/login');
        }
    }, [error]);
    return user ? _jsx(Outlet, {}) : _jsx(Navigate, { to: "/login" });
});
