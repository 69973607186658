var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useState } from "react";
import useDebounce from "~/Utils/useDebounce";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from "@mui/material/Tooltip";
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
var DefaultState = {
    title: "复制",
    color: "inherit",
    icon: _jsx(ContentCopyIcon, {})
};
var OkState = {
    title: "复制成功!",
    color: "success",
    icon: _jsx(CheckIcon, {})
};
var ErrorState = {
    title: "失败…",
    color: "error",
    icon: _jsx(ErrorTwoToneIcon, {})
};
export default (function (_a) {
    var text = _a.text;
    var _b = useState(DefaultState), state = _b[0], setState = _b[1];
    var 
    // debouncing,
    startDebounce = useDebounce(1000).startDebounce;
    var onClick = function () {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text);
            setState(OkState);
        }
        else {
            setState(__assign(__assign({}, ErrorState), { title: "浏览器不支持" }));
        }
        startDebounce(function () { return setState(DefaultState); });
    };
    return _jsx(Tooltip, { title: state.title, placement: "top", arrow: true, children: _jsx(IconButton, { size: "small", color: state.color, onClick: onClick, children: state.icon }) });
});
