import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import useAuth from "~/Components/Auth/useAuth";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useCallback, useMemo, useState } from "react";
import { ReqJsonToType } from "~/Utils/Req";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LinearProgress from "@mui/material/LinearProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import useTheme from "@mui/material/styles/useTheme";
import Style from './index.scss';
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import ChatInput from "../ChatInput";
import ExtractOptions from '~/Utils/ExtractOptions';
// import TooltipConfig from "./TooltipConfig";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import enqueueSnackbar from "~/Utils/EnqueueSnackbar";
import AlertSimple from "~/Components/AlertSimple";
import ProgressOverlay from "~/Components/ProgressOverlay";
import useFetch from "~/Utils/useFetch";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import useApi from "~/Components/useApi";
import ExtractSelector from './ExtractSelector';
var CustomText = function (_a) {
    var personaCustom = _a.personaCustom, setPersonaCustom = _a.setPersonaCustom;
    return _jsx(TextField, { multiline: true, variant: "filled", label: "\u8F93\u5165\u81EA\u5B9A\u4E49\u4EBA\u683C", fullWidth: true, value: personaCustom, minRows: 3, onChange: function (e) { return setPersonaCustom(e.target.value); } });
};
export default (function (_a) {
    var onAddTopic = _a.onAddTopic;
    var user = useAuth().user;
    var _b = user, jwt = _b.jwt, allowPersonaPick = _b.allow_persona_pick, allowPersonaCustom = _b.allow_persona_custom;
    var _c = useState(function () {
        if (!allowPersonaPick && allowPersonaCustom) {
            return "__custom__";
        }
        return "";
    }), personaPick = _c[0], setPersonaPick = _c[1];
    var _d = useState(""), personaCustom = _d[0], setPersonaCustom = _d[1];
    // const [apiPicked, setApiPicked] = useState<number>(apiPick === 0? 1: apiPick);
    var _e = useApi(), apiPicked = _e.apiPick, setApiPicked = _e.setApiPick;
    var _f = useState(""), firstChatStr = _f[0], setFirstChatStr = _f[1];
    var _g = useState(ExtractOptions[0]), extractOption = _g[0], setExtractOption = _g[1];
    var _h = useState(false), loading = _h[0], setLoading = _h[1];
    var _j = useState(null), error = _j[0], setError = _j[1];
    // 不会中途修改权限，所以这个 useFetch 不会触发 react 的 hook 问题
    var _k = allowPersonaPick
        ? useFetch('/system_preload', [], {
            headers: {
                'Content-Type': 'application/json',
                'X-JWT': jwt,
            },
        }, function (resp) { return resp.json().then(function (result) { return result; }); })
        : { loading: false, error: null, data: [], reloadCallback: function () { } }, systemPreloadLoading = _k.loading, systemPreloadError = _k.error, systemPreloads = _k.data, systemPreloadReload = _k.reloadCallback;
    var navigate = useNavigate();
    var onSubmit = useCallback(function (firstChatFinal) {
        if (loading) {
            enqueueSnackbar('请等待请求完成', 'info');
            return;
        }
        var payload = {
            persona_pick: personaPick,
            persona_custom: personaPick === "__custom__" ? personaCustom : "",
            extract_type: extractOption.value,
            user_init: firstChatFinal,
            api: apiPicked,
        };
        console.log(payload);
        setLoading(true);
        setError(null);
        ReqJsonToType('/topic', {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
                'X-JWT': jwt,
            },
        }).then(function (topic) {
            setLoading(false);
            onAddTopic(topic);
            navigate("/chat/".concat(topic.id));
        }).catch(function (error) {
            setError(error);
            setLoading(false);
            enqueueSnackbar("\u53D1\u8D77\u4F1A\u8BDD\u5931\u8D25: ".concat(error.message), 'error');
        });
    }, [personaPick, personaCustom, extractOption, jwt]);
    var theme = useTheme();
    var systemPreloadExtractName = useMemo(function () {
        for (var _i = 0, systemPreloads_1 = systemPreloads; _i < systemPreloads_1.length; _i++) {
            var preload = systemPreloads_1[_i];
            if (preload.id === personaPick) {
                var curExtractType = preload.extract_type;
                for (var _a = 0, ExtractOptions_1 = ExtractOptions; _a < ExtractOptions_1.length; _a++) {
                    var _b = ExtractOptions_1[_a], value = _b.value, name_1 = _b.name;
                    if (value === curExtractType) {
                        return name_1;
                    }
                }
                return curExtractType;
            }
        }
        return "";
    }, [
        personaPick,
        systemPreloads,
    ]);
    var canAiStartFirst = useMemo(function () {
        if (personaPick === "") {
            return false;
        }
        if (personaPick === "__custom__") {
            return personaCustom !== "";
        }
        return true;
    }, [
        personaPick,
        personaCustom,
    ]);
    var personPickOptions = [];
    if (allowPersonaPick || allowPersonaCustom) {
        personPickOptions.push({
            name: "[无]",
            id: "",
        });
    }
    if (allowPersonaCustom) {
        personPickOptions.push({
            name: "[自定义]",
            id: "__custom__",
        });
    }
    var showCustomText = allowPersonaCustom;
    if (allowPersonaPick && allowPersonaCustom) {
        showCustomText = personaPick === "__custom__";
    }
    if (!allowPersonaPick && !allowPersonaCustom) {
        console.log("short cut nothing can be picked");
        return _jsxs(Container, { maxWidth: "md", children: [_jsx(Typography, { color: "GrayText", children: "\u8F93\u5165\u6587\u5B57\u5E76\u5F00\u59CB\u804A\u5929" }), _jsx(ChatInput, { apiPicked: apiPicked, setApiPicked: setApiPicked, inputStr: firstChatStr, setInputStr: setFirstChatStr, onEnter: function () { return onSubmit(firstChatStr.trim()); }, loading: loading })] });
    }
    if (!allowPersonaPick && allowPersonaCustom) {
        return _jsxs(Container, { maxWidth: "md", sx: {
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(2),
            }, children: [_jsx(CustomText, { personaCustom: personaCustom, setPersonaCustom: setPersonaCustom }), _jsx(ExtractSelector, { extractOption: extractOption, setExtractOption: setExtractOption }), canAiStartFirst && _jsx(Box, { className: Style.buttonsRight, children: _jsx(LoadingButton, { variant: "outlined", onClick: function () {
                            setFirstChatStr('');
                            onSubmit('');
                        }, children: "AI \u5148\u624B" }) }), _jsx(ChatInput, { apiPicked: apiPicked, setApiPicked: setApiPicked, inputStr: firstChatStr, setInputStr: setFirstChatStr, onEnter: function () { return onSubmit(firstChatStr.trim()); }, loading: loading })] });
    }
    return _jsx(Container, { maxWidth: "md", children: _jsxs(ProgressOverlay, { loading: loading, children: [_jsxs(Paper, { className: Style.paper, sx: {
                        gap: theme.spacing(2),
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                        padding: theme.spacing(2),
                    }, children: [_jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { children: "\u9009\u62E9\u4EBA\u683C" }), _jsxs(Select, { value: personaPick, label: "\u9009\u62E9\u4EBA\u683C", fullWidth: true, onChange: function (e) { return setPersonaPick(e.target.value); }, children: [personPickOptions.map(function (_a) {
                                            var id = _a.id, name = _a.name;
                                            return _jsx(MenuItem, { value: id, children: name }, id);
                                        }), _jsx(Divider, {}), systemPreloads.map(function (_a) {
                                            var id = _a.id, name = _a.name;
                                            return _jsx(MenuItem, { value: id, children: name }, id);
                                        }), systemPreloadLoading && _jsx(LinearProgress, {}), systemPreloadError && _jsx(AlertSimple, { severity: "error", onReload: systemPreloadReload, children: systemPreloadError.message })] })] }), showCustomText && _jsx(CustomText, { personaCustom: personaCustom, setPersonaCustom: setPersonaCustom }), personaPick === "__custom__" && _jsx(ExtractSelector, { extractOption: extractOption, setExtractOption: setExtractOption }), personaPick !== "__custom__" && personaPick !== "" && systemPreloadExtractName != "无" && _jsxs(Stack, { gap: 1, direction: "row", style: { alignItems: 'center' }, children: [_jsx(Typography, { children: "\u8BC6\u522B:" }), _jsx(Chip, { label: systemPreloadExtractName })] }), canAiStartFirst && _jsx(Box, { className: Style.buttonsRight, children: _jsx(LoadingButton, { variant: "outlined", onClick: function () {
                                    setFirstChatStr('');
                                    onSubmit('');
                                }, children: "AI \u5148\u624B" }) }), error && _jsx(AlertSimple, { severity: "error", onClose: function () { return setError(null); }, children: error.message })] }), _jsx(ChatInput, { apiPicked: apiPicked, setApiPicked: setApiPicked, inputStr: firstChatStr, setInputStr: setFirstChatStr, onEnter: function () { return onSubmit(firstChatStr.trim()); }, loading: loading })] }) });
});
