import { jsx as _jsx } from "@emotion/react/jsx-runtime";
// import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
// import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import DataUsageIcon from '@mui/icons-material/DataUsage';
// import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import Style from './index.css';
export var rotateBase = Style.rotateBase;
export var rotate90 = Style.rotate90;
export var rotateLoopAnimationBaseTime = Style.rotateLoopAnimationBaseTime;
// export const ReloadingIcon = () => <ReplayTwoToneIcon classes={{root: Style.rotateLoopAnimationBaseTime}} />;
export var ReloadingIcon = function () { return _jsx(DataUsageIcon, { classes: { root: Style.rotateLoopAnimationBaseTime } }); };
