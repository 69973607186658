import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import NewModal from "./NewModal";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import Groups2TwoToneIcon from '@mui/icons-material/Groups2TwoTone';
import PersonPinTwoToneIcon from '@mui/icons-material/PersonPinTwoTone';
import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import apiOptions from "~/Utils/ApiOptions";
export default (function (u) {
    var _a = useState(u), user = _a[0], setUser = _a[1];
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    // console.log(`user`, user);
    return _jsxs(_Fragment, { children: [_jsxs(TableRow, { children: [_jsx(TableCell, { children: user.id }), _jsx(TableCell, { children: user.account }), _jsx(TableCell, { children: user.comment }), _jsx(TableCell, { children: user.allow_login ? _jsx(Typography, { children: "\u662F" }) : _jsx(Typography, { color: "GrayText", children: "\u5426" }) }), _jsx(TableCell, { children: _jsxs(Stack, { direction: "row", gap: 1, flexWrap: "wrap", children: [user.is_admin && _jsx(Chip, { label: "\u7BA1\u7406\u5458", icon: _jsx(AdminPanelSettingsTwoToneIcon, {}) }), user.allow_persona_pick && _jsx(Chip, { label: "\u9009\u4EBA\u683C", icon: _jsx(Groups2TwoToneIcon, {}) }), user.allow_persona_custom && _jsx(Chip, { label: "\u62DF\u4EBA\u683C", icon: _jsx(PersonPinTwoToneIcon, {}) }), {
                                    0: _jsx(Chip, { label: "\u9009\u63A5\u53E3", icon: _jsx(SmartToyTwoToneIcon, { color: "info" }) }),
                                    1: _jsx(Chip, { label: user.api_model, avatar: _jsx(Avatar, { children: _jsx(Typography, { color: apiOptions.filter(function (each) { return each.pick === 1; })[0].color, children: "\u7C73" }) }) }),
                                    2: _jsx(Chip, { label: user.api_model, avatar: _jsx(Avatar, { children: _jsx(Typography, { color: apiOptions.filter(function (each) { return each.pick === 2; })[0].color, children: "\u5965" }) }) }),
                                }[user.api_pick]] }) }), _jsx(TableCell, { children: _jsx(Button, { variant: "outlined", color: "primary", startIcon: _jsx(EditTwoToneIcon, {}), onClick: function () { return setOpen(true); }, children: "\u7F16\u8F91" }) })] }), _jsx(NewModal, { user: user, open: open, setClose: function () { return setOpen(false); }, onFinished: setUser })] });
});
