var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            error: undefined,
            info: {
                componentStack: '',
            },
        };
        return _this;
    }
    ErrorBoundary.prototype.componentDidCatch = function (error, info) {
        this.setState({ error: error, info: info });
    };
    ErrorBoundary.prototype.componentWillUnmount = function () {
        this.state = {};
    };
    ErrorBoundary.prototype.render = function () {
        var _a = this.props, message = _a.message, description = _a.description, children = _a.children;
        var _b = this.state, error = _b.error, info = _b.info;
        var componentStack = info && info.componentStack ? info.componentStack : null;
        var errorMessage = typeof message === 'undefined' ? (error || '').toString() : message;
        var errorDescription = typeof description === 'undefined' ? componentStack : description;
        if (error) {
            return _jsxs(Alert, { severity: "error", children: [_jsx(AlertTitle, { children: errorMessage }), _jsx(Typography, { variant: "body1", component: "div", children: _jsx("pre", { style: { fontSize: '0.9em', overflowX: 'auto' }, children: errorDescription }) })] });
        }
        return children;
    };
    return ErrorBoundary;
}(React.Component));
export default ErrorBoundary;
