var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import LoadingButton from "@mui/lab/LoadingButton";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import useTheme from "@mui/material/styles/useTheme";
import { useCallback, useState } from "react";
import AlertSimple from "~/Components/AlertSimple";
import useAuth from "~/Components/Auth/useAuth";
import { ReqJsonToType } from "~/Utils/Req";
import enqueueSnackbar from "~/Utils/EnqueueSnackbar";
export default (function () {
    var _a = useAuth(), user = _a.user, login = _a.login;
    var _b = user, jwt = _b.jwt, id = _b.id, nickname = _b.nickname;
    var _c = useState({
        loading: false,
        error: null,
    }), apiState = _c[0], setApiState = _c[1];
    var onSubmit = useCallback(function (evt) {
        evt.preventDefault();
        var newNickName = evt.target.nickname.value;
        var nickStrip = newNickName.trim();
        if (nickStrip === '') {
            enqueueSnackbar('昵称不能为空', 'error');
            return;
        }
        if (nickStrip === nickname) {
            enqueueSnackbar('昵称未修改', 'info');
            return;
        }
        setApiState({ loading: true, error: null });
        // TODO: loading
        ReqJsonToType("/user/".concat(id), {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-JWT': jwt,
            },
            body: JSON.stringify({
                nickname: nickStrip,
            }),
        })
            .then(function (userInfo) {
            setApiState({ loading: false, error: null });
            login(__assign(__assign({}, userInfo), { jwt: jwt }));
            enqueueSnackbar("\u4FEE\u6539\u6210\u529F: ".concat(userInfo.nickname), 'success');
        })
            .catch(function (error) {
            setApiState({ loading: false, error: error });
            enqueueSnackbar(error.message, 'error');
        });
    }, []);
    var theme = useTheme();
    return _jsx(Paper, { children: _jsx("form", { method: "post", onSubmit: onSubmit, children: _jsxs(Stack, { gap: 1, sx: {
                    padding: theme.spacing(2)
                }, children: [_jsx(Divider, { children: "\u7F16\u8F91\u57FA\u672C\u4FE1\u606F" }), _jsx(TextField, { fullWidth: true, disabled: apiState.loading, name: "nickname", defaultValue: nickname, label: "\u6635\u79F0", required: true }), apiState.error && _jsx(AlertSimple, { severity: "error", onClose: function () { return setApiState(function (old) { return (__assign(__assign({}, old), { error: null })); }); }, children: apiState.error.message }), _jsx(LoadingButton, { fullWidth: true, type: "submit", loading: apiState.loading, children: "\u63D0\u4EA4" })] }) }) });
});
