import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import Stack from "@mui/material/Stack";
import useTheme from "@mui/material/styles/useTheme";
import BasicInfoEdit from "./BasicInfoEdit";
import PasswordEdit from "./PasswordEdit";
export default (function () {
    var theme = useTheme();
    return _jsxs(Stack, { gap: 2, sx: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        }, children: [_jsx(BasicInfoEdit, {}), _jsx(PasswordEdit, {})] });
});
