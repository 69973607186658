var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useState } from "react";
import { ENDPOINT } from './fetchCommon';
var FetchError = /** @class */ (function (_super) {
    __extends(FetchError, _super);
    // status: number | undefined;
    function FetchError(status, message) {
        var _this = _super.call(this, message) || this;
        _this.status = status;
        return _this;
    }
    return FetchError;
}(Error));
export { FetchError };
export default (function (url, defaultValue, init, transform) {
    if (init === void 0) { init = {}; }
    var _a = useState(true), loading = _a[0], setLoading = _a[1];
    var _b = useState(defaultValue), data = _b[0], setData = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var _d = useState(1), reloadMark = _d[0], setReloadMark = _d[1];
    var _e = useState(null), abortController = _e[0], setAbortController = _e[1];
    var setDataNoLoading = function (value) {
        // console.log(`set data`);
        setData(value);
        setError(null);
        setLoading(false);
    };
    var setErrorNoLoading = function (error) {
        // console.log(error);
        // console.log(error.name);
        // if(error instanceof DOMException && error.name === 'AbortError') {
        //     setData(defaultValue);
        //     setError(null);
        //     setLoading(false);
        //     return;
        // }
        if (!(error instanceof DOMException && error.name === 'AbortError')) {
            console.error(error);
        }
        // console.log(`set error`);
        setError(error);
        setData(defaultValue);
        setLoading(false);
    };
    useEffect(function () {
        setLoading(true);
        setError(null);
        var newController = new AbortController();
        setAbortController(function (oldController) {
            oldController === null || oldController === void 0 ? void 0 : oldController.abort();
            return newController;
        });
        fetch("".concat(ENDPOINT).concat(url), __assign(__assign({}, init), { signal: newController.signal }))
            .then(function (response) {
            var status = response.status, statusText = response.statusText;
            if (status < 200 || status >= 300) {
                var message_1 = "[".concat(status, "] ").concat(statusText);
                response
                    .json()
                    .then(function (_a) {
                    var serverMsg = _a.message;
                    if (serverMsg !== undefined && serverMsg !== null && serverMsg !== '') {
                        message_1 = serverMsg;
                    }
                    var error = new FetchError(status, message_1);
                    setErrorNoLoading(error);
                })
                    .catch(function (e) {
                    console.log(e);
                    var error = new FetchError(status, message_1);
                    setErrorNoLoading(error);
                });
                return;
            }
            if (transform) {
                transform(response)
                    .then(setDataNoLoading)
                    .catch(setErrorNoLoading);
                return;
            }
            response
                .json()
                .then(function (result) { return setDataNoLoading(result); })
                .catch(setErrorNoLoading);
        })
            .catch(function (error) { return setErrorNoLoading(new FetchError(undefined, error.message)); });
        // .finally((): void => setLoading(false));
        return function () { return abortController === null || abortController === void 0 ? void 0 : abortController.abort(); };
    }, [url, reloadMark]);
    return {
        loading: loading,
        data: data,
        error: error,
        reloadCallback: function () {
            // abortController.abort();
            // setAbortController(new AbortController());
            // setAbortController(oldController => {
            //     oldController.abort();
            //     return new AbortController();
            // })
            setReloadMark(function (orl) { return -orl; });
        },
        overrideData: function (newValueOrCallback) {
            // setAbortController(oldController => {
            //     oldController.abort();
            //     return new AbortController();
            // });
            setAbortController(function (oldController) {
                oldController === null || oldController === void 0 ? void 0 : oldController.abort();
                return null;
            });
            setData(newValueOrCallback);
            setLoading(false);
            setError(null);
        }
    };
});
