var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import ReactMarkdown from "react-markdown";
import CardStyle from '../MessageCardBase/index.scss';
import { deepOrange, deepPurple } from '@mui/material/colors';
import React, { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import CopyButton from '~/Components/CopyButton';
import Tooltip from '@mui/material/Tooltip';
var ExpandMore = styled(function (props) {
    var expand = props.expand, other = __rest(props, ["expand"]);
    return _jsx(IconButton, __assign({}, other));
})(function (_a) {
    var theme = _a.theme, expand = _a.expand;
    return ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    });
});
export default (function (_a) {
    var role = _a.role, strippedContent = _a.stripped_content, tooltips = _a.tooltips, nickName = _a.nickName, avatar = _a.avatar, unfoldAll = _a.unfoldAll, setUnfoldAll = _a.setUnfoldAll;
    var _b = React.useState(false), showTooltips = _b[0], setShowTooltips = _b[1];
    useEffect(function () {
        if (unfoldAll !== showTooltips) {
            setShowTooltips(unfoldAll);
        }
    }, [unfoldAll]);
    if (role === "system") {
        // return <Card className={CardStyle.card} variant="outlined" sx={{alignSelf: "center"}}>
        //     {content}
        // </Card>
        return _jsx(Card, { variant: "outlined", children: _jsx(CardContent, { className: CardStyle.messageContent, children: _jsx("pre", { style: {
                        whiteSpace: 'pre-wrap',
                    }, children: strippedContent }) }) });
    }
    var showNickName = "";
    if (nickName !== null && nickName !== "") {
        showNickName = nickName;
    }
    else {
        showNickName = role === "user" ? "你" : "ChatGPT";
    }
    var showAvatar;
    if (avatar === "") {
        showAvatar = _jsx(Avatar, { sx: { bgcolor: role === "user" ? deepOrange[500] : deepPurple[500] }, children: showNickName[0] });
    }
    else {
        showAvatar = _jsx(Avatar, { src: avatar });
    }
    var hasContentTooltips = tooltips.filter(function (_a) {
        var content = _a.content;
        return content !== "";
    });
    var isUser = role === "user";
    var hasTooltipExpand = !isUser && hasContentTooltips.length > 0;
    return _jsxs(Card, { className: CardStyle.card, variant: "outlined", sx: {
            alignSelf: isUser ? "flex-end" : "flex-start",
        }, children: [_jsx(CardHeader, { avatar: showAvatar, title: showNickName }), _jsx(CardContent, { className: CardStyle.messageContent, children: _jsx(ReactMarkdown, { children: strippedContent }) }), _jsxs(CardActions, { disableSpacing: true, children: [_jsx(CopyButton, { text: strippedContent }), !isUser && _jsx(IconButton, { size: "small", onClick: function () { return setUnfoldAll(function (old) { return !old; }); }, children: unfoldAll
                            ? _jsx(Tooltip, { title: "\u6536\u8D77\u5168\u90E8", placement: "top", arrow: true, children: _jsx(KeyboardDoubleArrowUpIcon, {}) })
                            : _jsx(Tooltip, { title: "\u5C55\u5F00\u5168\u90E8", placement: "top", arrow: true, children: _jsx(KeyboardDoubleArrowDownIcon, {}) }) }), hasTooltipExpand && _jsx(Tooltip, { title: showTooltips ? "收起" : "展开", placement: "top", arrow: true, children: _jsx(ExpandMore, { expand: showTooltips, onClick: function () { return setShowTooltips(function (old) { return !old; }); }, children: _jsx(ExpandMoreIcon, {}) }) })] }), _jsxs(Collapse, { in: hasTooltipExpand && (showTooltips), timeout: "auto", children: [_jsx(Divider, {}), hasContentTooltips.map(function (_a, index) {
                        var id = _a.id, start = _a.start, end = _a.end, content = _a.content;
                        return _jsxs(Stack, { direction: "row", gap: 1, children: [_jsxs(Box, { sx: { display: 'inline-flex' }, children: [_jsx(Typography, { component: "span", color: "Highlight", children: start }), _jsx(Typography, { component: "span", color: "gray", children: id }), _jsx(Typography, { component: "span", color: "Highlight", children: end }), _jsx(Divider, { orientation: "vertical" })] }), _jsx(Typography, { children: content })] }, index);
                    }), _jsx(Divider, {})] })] });
});
