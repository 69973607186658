var Status;
(function (Status) {
    Status[Status["Pending"] = 0] = "Pending";
    Status[Status["Fulfilled"] = 1] = "Fulfilled";
    Status[Status["Rejected"] = 2] = "Rejected";
})(Status || (Status = {}));
export default (function (promise) {
    var status = Status.Pending;
    var error;
    var result;
    var suspender = promise.then(function (r) {
        status = Status.Fulfilled;
        result = r;
    })
        .catch(function (err) {
        status = Status.Rejected;
        error = err;
    });
    return function () {
        switch (status) {
            case Status.Pending:
                throw suspender;
            case Status.Rejected:
                throw error;
            case Status.Fulfilled:
                return result;
            default:
                throw Error("Unexpected arg ".concat(status));
        }
    };
});
