import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Suspense, useEffect, useState } from "react";
import Suspendable from "~/Utils/Suspendable";
import RetryErrorBoundary from "~/Components/RetryErrorBoundary";
export default (function (_a) {
    var makePromise = _a.makePromise, fallback = _a.fallback, Renderer = _a.renderer, noTrace = _a.noTrace;
    var _b = useState(null), states = _b[0], setStates = _b[1];
    var doRetry = function () {
        setStates(function (oldState) {
            oldState === null || oldState === void 0 ? void 0 : oldState.abortController.abort();
            var abortController = new AbortController();
            return {
                abortController: abortController,
                retryKey: oldState ? (-oldState.retryKey) : Math.random(),
                getResource: Suspendable(makePromise(abortController)),
            };
        });
    };
    useEffect(function () {
        doRetry();
    }, [makePromise]);
    useEffect(function () {
        return function () {
            console.log("cleanup ".concat(states === null || states === void 0 ? void 0 : states.retryKey));
            states === null || states === void 0 ? void 0 : states.abortController.abort();
        };
    }, []);
    if (states === null) {
        return fallback;
    }
    return _jsx(RetryErrorBoundary, { onRetry: doRetry, noTrace: noTrace, children: _jsx(Suspense, { fallback: fallback, children: _jsx(Renderer, { getResource: states.getResource }) }) }, states.retryKey);
});
