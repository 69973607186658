// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index__inputContainer--r0Gyj{display:flex}
`, "",{"version":3,"sources":["webpack://./src/Pages/Chat/ChatInput/index.scss"],"names":[],"mappings":"AAAA,8BACI,YAAa","sourcesContent":[".inputContainer {\n    display: flex;\n    // justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `index__inputContainer--r0Gyj`
};
export default ___CSS_LOADER_EXPORT___;
