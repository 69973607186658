var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import Drawer from "@mui/material/Drawer";
import useTheme from "@mui/material/styles/useTheme";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AddCommentIcon from '@mui/icons-material/AddComment';
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import useAuth from "~/Components/Auth/useAuth";
import useFetch from "~/Utils/useFetch";
import ProgressOverlay from "~/Components/ProgressOverlay";
import AlertSimple from "~/Components/AlertSimple";
import { Link, useNavigate } from "react-router-dom";
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import ThemeTypeToggle from "./ThemeTypeToggle";
import TopicNameAutoCheck from "./TopicName";
export default forwardRef(function (_a, ref) {
    var autoHide = _a.autoHide, open = _a.open, setClose = _a.setClose, topicId = _a.topicId;
    var theme = useTheme();
    var width = theme.spacing(28);
    var user = useAuth().user;
    var _b = user, jwt = _b.jwt, account = _b.account, nickname = _b.nickname, avatar = _b.avatar;
    var showName = nickname === '' ? account : nickname;
    var _c = useState([]), topics = _c[0], setTopics = _c[1];
    console.assert(jwt);
    var _d = useFetch("/topic", [], {
        headers: {
            'X-JWT': jwt,
        }
    }), loading = _d.loading, topicsData = _d.data, error = _d.error, reloadCallback = _d.reloadCallback;
    useEffect(function () {
        setTopics(topicsData);
    }, [topicsData]);
    useImperativeHandle(ref, function () { return ({
        addTopic: function (topic) { return setTopics(function (old) { return __spreadArray([topic], old, true); }); },
    }); });
    var navigate = useNavigate();
    // const 
    return _jsxs(Drawer, { sx: {
            width: width,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: width,
                boxSizing: 'border-box',
                justifyContent: 'space-between',
            },
        }, variant: "persistent", anchor: "left", open: open, children: [_jsxs(Box, { children: [_jsxs(List, { children: [_jsx(ListItem, { disablePadding: true, children: _jsxs(ListItemButton, { onClick: setClose, children: [_jsx(ListItemIcon, { children: _jsx(ChevronLeftIcon, {}) }), _jsx(ListItemText, { primary: "\u6536\u8D77" })] }) }), _jsx(ListItem, { disablePadding: true, children: _jsxs(ListItemButton, { selected: topicId === null, onClick: function () {
                                        if (topicId !== null) {
                                            navigate('/');
                                            if (autoHide) {
                                                setClose();
                                            }
                                        }
                                    }, children: [_jsx(ListItemIcon, { children: _jsx(AddCommentIcon, {}) }), _jsx(ListItemText, { primary: "\u65B0\u5BF9\u8BDD" })] }) })] }), _jsx(Divider, {}), _jsxs(ProgressOverlay, { loading: loading, children: [error && _jsx(AlertSimple, { severity: "error", onReload: reloadCallback, children: error.message }), topics.map(function (topic) { return (_jsx(ListItem, { disablePadding: true, children: _jsx(ListItemButton, { selected: topic.id === topicId, onClick: function () {
                                        if (topic.id !== topicId) {
                                            navigate("/chat/".concat(topic.id));
                                            if (autoHide) {
                                                setClose();
                                            }
                                        }
                                    }, children: _jsx(TopicNameAutoCheck, { topic: topic, onDeleted: function () {
                                            navigate("/");
                                            setTopics(function (old) { return old.filter(function (t) { return t.id !== topic.id; }); });
                                        } }) }) }, topic.id)); })] })] }), _jsxs(Box, { children: [_jsx(Divider, {}), _jsxs(List, { children: [_jsx(ListItem, { disablePadding: true, children: _jsx(ThemeTypeToggle, {}) }), _jsx(ListItem, { disablePadding: true, children: _jsxs(ListItemButton, { component: Link, to: "/dashboard", children: [_jsx(ListItemIcon, { children: _jsx(Avatar, { src: avatar === '' ? undefined : avatar, children: avatar === '' ? showName[0] : undefined }) }), _jsx(ListItemText, { primary: showName })] }) }), _jsx(ListItem, { disablePadding: true, children: _jsxs(ListItemButton, { component: Link, to: "/logout", children: [_jsx(ListItemIcon, { children: _jsx(LogoutTwoToneIcon, {}) }), _jsx(ListItemText, { primary: "\u767B\u51FA" })] }) })] })] })] });
});
