// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index__paper--KYmIk{display:flex;flex-direction:column;align-items:flex-start}.index__buttonsRight--M4INZ{width:100%;display:flex;justify-content:flex-end}
`, "",{"version":3,"sources":["webpack://./src/Pages/Chat/NewTopic/index.scss"],"names":[],"mappings":"AAAA,qBACI,YAAa,CACb,qBAAsB,CACtB,sBAAuB,CAC1B,4BAGG,UAAW,CACX,YAAa,CACb,wBAAyB","sourcesContent":[".paper {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n}\n\n.buttonsRight {\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": `index__paper--KYmIk`,
	"buttonsRight": `index__buttonsRight--M4INZ`
};
export default ___CSS_LOADER_EXPORT___;
