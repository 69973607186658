var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import Paper from "@mui/material/Paper";
import useTheme from "@mui/material/styles/useTheme";
import { Fragment, useCallback, useEffect, useState } from "react";
import useAuth from "~/Components/Auth/useAuth";
import { ReqJsonToType } from "~/Utils/Req";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import ControlPointTwoToneIcon from '@mui/icons-material/ControlPointTwoTone';
import NewModal from "./NewModal";
import LinearProgress from "@mui/material/LinearProgress";
import AlertSimple from "~/Components/AlertSimple";
import UserTableRow from "./UserTableRow";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
export default (function () {
    var _a;
    var user = useAuth().user;
    var jwt = user.jwt;
    var _b = useState({
        loading: false,
        error: null,
        users: [],
        abortController: null,
    }), apiState = _b[0], setApiState = _b[1];
    var _c = useState(1), fetchKey = _c[0], setFetchKey = _c[1];
    // const [prependUsers, setPrependUsers] = useState<EditUser[]>([]);
    var fetchUsers = useCallback(function () {
        var newController = new AbortController();
        setApiState(function (old) {
            var _a;
            (_a = old.abortController) === null || _a === void 0 ? void 0 : _a.abort();
            return (__assign(__assign({}, old), { loading: true, error: null, abortController: newController }));
        });
        ReqJsonToType("/user", { headers: { 'X-JWT': jwt }, signal: newController.signal })
            .then(function (users) {
            // setPrependUsers([])
            setApiState(function (old) { return (__assign(__assign({}, old), { loading: false, error: null, users: users })); });
            setFetchKey(function (old) { return -old; });
        })
            .catch(function (error) {
            setApiState(function (old) { return (__assign(__assign({}, old), { loading: false, error: error })); });
        });
    }, []);
    useEffect(function () {
        fetchUsers();
        return function () {
            var _a;
            (_a = apiState.abortController) === null || _a === void 0 ? void 0 : _a.abort();
        };
    }, []);
    var theme = useTheme();
    var _d = useState({
        open: false,
        user: {},
    }), modelProps = _d[0], setModelProps = _d[1];
    var addUser = useCallback(function () {
        setModelProps(function (old) { return (__assign(__assign({}, old), { open: true, user: { allow_login: true } })); });
    }, []);
    return _jsxs(_Fragment, { children: [_jsxs(Paper, { sx: {
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                }, children: [_jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "ID" }), _jsx(TableCell, { children: "\u5E10\u53F7" }), _jsx(TableCell, { children: "\u5907\u6CE8" }), _jsx(TableCell, { children: "\u53EF\u767B\u5F55" }), _jsx(TableCell, { children: "\u6743\u9650" }), _jsx(TableCell, { children: _jsx(Button, { variant: "outlined", color: "primary", startIcon: _jsx(ControlPointTwoToneIcon, {}), onClick: addUser, children: "\u65B0\u589E" }) })] }) }), _jsxs(TableBody, { children: [apiState.loading && _jsx(TableRow, { children: _jsx(TableCell, { colSpan: 7, children: _jsx(LinearProgress, { color: "secondary" }) }) }), apiState.error && _jsx(TableRow, { children: _jsx(TableCell, { colSpan: 7, children: _jsx(AlertSimple, { severity: "error", onClose: fetchUsers, children: apiState.error.message }) }) }), _jsx(Fragment, { children: apiState.users.map(function (user) { return _jsx(UserTableRow, __assign({}, user), user.id); }) }, fetchKey)] })] }) }), _jsx(Box, { sx: { textAlign: 'right' }, children: _jsx(LoadingButton, { startIcon: _jsx(RefreshTwoToneIcon, {}), loading: apiState.loading, onClick: fetchUsers, children: "\u5237\u65B0" }) })] }), _jsx(NewModal, __assign({}, modelProps, { setClose: function () { return setModelProps(function (old) { return (__assign(__assign({}, old), { open: false })); }); }, onFinished: function (user) {
                    console.log("prepend user", user);
                    // setPrependUsers(old => [user, ...old]);
                    setApiState(function (old) { return (__assign(__assign({}, old), { users: __spreadArray([user], old.users, true) })); });
                } }), (_a = modelProps.user.id) !== null && _a !== void 0 ? _a : -1)] });
});
