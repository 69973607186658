import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import CircularProgress from '@mui/material/CircularProgress';
import Style from './index.css';
import { useTheme } from '@mui/material/styles';
export default (function (_a) {
    var loading = _a.loading, height = _a.height, children = _a.children;
    if (!loading) {
        return _jsx(_Fragment, { children: children });
    }
    var style = height ? { height: typeof height === 'number' ? "".concat(height, "px") : height } : {};
    var theme = useTheme();
    // console.log(theme.dim);
    return _jsxs("div", { className: Style.relative, style: style, children: [children, _jsx("div", { className: Style.overlay, style: { backgroundColor: theme.dim }, children: _jsx("div", { className: Style.progress, children: _jsx(CircularProgress, { size: 60, thickness: 3, classes: { root: Style.verticalPos } }) }) })] });
});
