import { yellow, grey } from "@mui/material/colors";
var apiOptions = [
    {
        pick: 1,
        models: [
            "gpt-4-1106",
            "gpt-35-turbo-1106",
            "gpt-35-turbo-16k"
        ],
        name: "米哈游",
        color: grey[500],
        // avatarColor: "inherent",
    },
    {
        pick: 2,
        models: [
            "gpt-4-turbo",
            "gpt-4",
            "gpt-4o",
            "gpt-3.5-turbo",
            "text-embedding-3-large"
        ],
        name: "奥秘",
        color: yellow[500],
        // avatarColor: "main.warning",
    },
];
export default apiOptions;
