import { useEffect, useState } from "react";
export default (function () {
    var _a = useState(null), abortController = _a[0], setAbortController = _a[1];
    useEffect(function () {
        setAbortController(function (old) {
            old === null || old === void 0 ? void 0 : old.abort();
            return new AbortController();
        });
        return function () {
            if (abortController !== null) {
                abortController.abort();
            }
        };
    }, []);
    return function () {
        var newAbortController = new AbortController();
        setAbortController(function (old) {
            old === null || old === void 0 ? void 0 : old.abort();
            return newAbortController;
        });
        return newAbortController.signal;
    };
});
