import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import Card from "@mui/material/Card";
import CardStyle from '../MessageCardBase/index.scss';
import Markdown from "react-markdown";
import CardContent from "@mui/material/CardContent";
import LinearProgress from '@mui/material/LinearProgress';
import AlertSimple from "~/Components/AlertSimple";
import CardActions from "@mui/material/CardActions";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from "@mui/material/IconButton";
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import Box from "@mui/material/Box";
export default (function (_a) {
    var loading = _a.loading, error = _a.error, strippedContent = _a.stripped_content, canCancel = _a.canCancel, onCancel = _a.onCancel, onRetry = _a.onRetry;
    return _jsxs(Card, { raised: true, className: CardStyle.card, sx: { alignSelf: 'flex-end' }, children: [_jsxs(CardContent, { children: [_jsx(Box, { className: CardStyle.messageContent, children: _jsx(Markdown, { children: strippedContent }) }), error && _jsx(AlertSimple, { severity: "error", children: error.message })] }), (canCancel || error !== null) && _jsxs(CardActions, { disableSpacing: true, sx: { alignItems: 'flex-end' }, children: [error && _jsx(IconButton, { onClick: onRetry, children: _jsx(RefreshOutlinedIcon, {}) }), (error || canCancel) && _jsx(IconButton, { onClick: function () { return onCancel(strippedContent); }, children: _jsx(DeleteOutlineOutlinedIcon, {}) })] }), loading && _jsx(LinearProgress, {})] });
});
