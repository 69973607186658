import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ErrorBoundary from '~/Components/ErrorBoundary';
import ThemeProvider from '~/Components/Theme/ThemeProvider';
// import { ToastContainer } from 'react-toastify';
import { AuthProvider } from '~/Components/Auth/useAuth';
import ProtectedRoute from '~/Components/Auth/ProtectedRoute';
import Layout from './Components/Layout';
import Login from '~/Pages/Login';
import Logout from '~/Pages/Logout';
import Chat from '~/Pages/Chat';
import DashboardProfile from '~/Pages/Dashboard/Profile';
import DashboardUser from '~/Pages/Dashboard/User';
import NotFound from '~/Pages/NotFound';
import '@fontsource/roboto/300.css';
import { CssBaseline } from '@mui/material';
import ToastContainer from '~/Components/ToastContainer';
import { ApiPickProvider } from './Components/useApi';
ReactDOM.createRoot(document.getElementById('root')).render(_jsx(_Fragment, { children: _jsxs(ThemeProvider, { children: [_jsx(CssBaseline, {}), _jsxs(ErrorBoundary, { children: [_jsx(ToastContainer, {}), _jsx(Router, { children: _jsx(AuthProvider, { children: _jsx(ApiPickProvider, { children: _jsx(HelmetProvider, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/login", element: _jsx(Login, {}) }), _jsx(Route, { path: "/logout", element: _jsx(Logout, {}) }), _jsxs(Route, { path: "/", element: _jsx(ProtectedRoute, {}), children: [_jsx(Route, { index: true, element: _jsx(Chat, {}) }), _jsx(Route, { path: "/chat/:topicId", element: _jsx(Chat, {}) }), _jsxs(Route, { path: "/dashboard", element: _jsx(Layout, {}), children: [_jsx(Route, { index: true, element: _jsx(DashboardProfile, {}) }), _jsx(Route, { path: "user", element: _jsx(DashboardUser, {}) })] })] }), _jsx(Route, { path: "*", element: _jsx(NotFound, {}) })] }) }) }) }) })] })] }) }));
