import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import ListItemText from "@mui/material/ListItemText";
import { useEffect, useState } from "react";
import useAuth from "~/Components/Auth/useAuth";
import { ReloadingIcon } from "~/Components/RotateClass";
import Typography from "@mui/material/Typography";
import { ENDPOINT } from "~/Utils/fetchCommon";
import styled from '@mui/material/styles/styled';
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Style from './index.scss';
import IconButton from "@mui/material/IconButton";
import MoreHorizTwoToneIcon from '@mui/icons-material/MoreHorizTwoTone';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ListItemIcon from "@mui/material/ListItemIcon";
import ChangeName from "./ChangeName";
import DeleteTopic from "./DeleteTopic";
var ListItemTextStyled = styled(ListItemText)({
    '& .MuiTypography-root': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});
export default (function (_a) {
    var _b = _a.topic, user_init = _b.user_init, topic = _b.topic, id = _b.id, onDeleted = _a.onDeleted;
    var _c = useState(false), aiRenameLoading = _c[0], setAiRenameLoading = _c[1];
    var _d = useState(topic === '' ? '无标题' : topic), displayName = _d[0], setDisplayName = _d[1];
    var _e = useState(false), rename = _e[0], setRename = _e[1];
    var _f = useState(null), anchorEl = _f[0], setAnchorEl = _f[1];
    var handleMenuButtonClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleMenuClose = function () {
        setAnchorEl(null);
    };
    var user = useAuth().user;
    var jwt = user.jwt;
    useEffect(function () {
        if (user_init === "" || topic !== "") {
            return;
        }
        setAiRenameLoading(true);
        var controller = new AbortController();
        fetch("".concat(ENDPOINT, "/topic/").concat(id, "/_ai_rename"), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-JWT': jwt,
            },
            signal: controller.signal,
        })
            .then(function (response) {
            setAiRenameLoading(false);
            var status = response.status;
            console.log(status);
            if (status !== 200) {
                return;
            }
            response.json()
                .then(function (_a) {
                var topic = _a.topic;
                setDisplayName(topic);
            });
        });
        return function () {
            controller.abort();
        };
    }, []);
    var theme = useTheme();
    return _jsx(ClickAwayListener, { onClickAway: function () { return setRename(false); }, children: _jsxs(Box, { width: 1, sx: {
                position: 'relative',
            }, children: [aiRenameLoading && !rename && _jsx(Typography, { color: "GrayText", sx: {
                        position: 'absolute',
                        right: 0,
                    }, children: _jsx(ReloadingIcon, {}) }), !rename && _jsx(ListItemTextStyled, { primary: displayName }), rename && _jsx(ChangeName, { id: id, defaultValue: displayName, onChanged: function (value) {
                        setRename(false);
                        setDisplayName(value);
                        setAiRenameLoading(false);
                    }, onCancel: function () { return setRename(false); } }), !rename && _jsx(Box, { sx: {
                        position: 'absolute',
                        right: '-10px',
                        top: 0,
                        height: '100%',
                        zIndex: theme.zIndex.drawer + 1,
                    }, className: Style.showIfParentHover, children: _jsx(IconButton, { size: "small", onClick: function (evt) {
                            evt.stopPropagation();
                            handleMenuButtonClick(evt);
                        }, children: _jsx(MoreHorizTwoToneIcon, {}) }) }), _jsxs(Menu, { anchorEl: anchorEl, open: Boolean(anchorEl), onClose: handleMenuClose, children: [_jsxs(MenuItem, { onClick: function () {
                                handleMenuClose();
                                setRename(true);
                            }, children: [_jsx(ListItemIcon, { children: _jsx(EditTwoToneIcon, {}) }), _jsx(ListItemText, { children: "\u91CD\u547D\u540D" })] }), _jsx(DeleteTopic, { onCloseMenu: handleMenuClose, onDeleted: onDeleted, id: id, topic: displayName })] })] }) });
});
