import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { createContext, useEffect, useMemo, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
// import { Theme } from "@mui/material/styles/createTheme";
import Dark from "./Dark";
import Light from "./Light";
// import Context, { ThemeType } from "./Context";
var getTheme = function (themeType) {
    switch (themeType) {
        case ThemeType.Dark:
            return Dark;
        case ThemeType.Light:
            return Light;
        default:
            throw new Error("Unknown theme ".concat(themeType));
    }
};
export var ThemeType;
(function (ThemeType) {
    ThemeType[ThemeType["Dark"] = 0] = "Dark";
    ThemeType[ThemeType["Light"] = 1] = "Light";
})(ThemeType || (ThemeType = {}));
export var Context = createContext({
    theme: ThemeType.Dark,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setTheme: function (themeType) {
        // init context
    }
});
// export const Context = createContext(getTheme(ThemeType.Dark));
export default (function (_a) {
    var children = _a.children;
    var _b = useState(localStorage.getItem("theme") === null ? ThemeType.Dark : ThemeType[localStorage.getItem("theme")]), theme = _b[0], setTheme = _b[1];
    var themeResult = useMemo(function () { return getTheme(theme); }, [theme]);
    useEffect(function () {
        localStorage.setItem("theme", ThemeType[theme]);
    }, [theme]);
    return _jsx(Context.Provider, { value: {
            theme: theme,
            setTheme: function (newValue) { return setTheme(newValue); },
        }, children: _jsx(ThemeProvider, { theme: themeResult, children: children }) });
});
