var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import LoadingButton from "@mui/lab/LoadingButton";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import useTheme from "@mui/material/styles/useTheme";
import { useCallback, useEffect, useState } from "react";
import AlertSimple from "~/Components/AlertSimple";
import useAuth from "~/Components/Auth/useAuth";
import { ReqJsonToType } from "~/Utils/Req";
import enqueueSnackbar from "~/Utils/EnqueueSnackbar";
export default (function () {
    var _a = useAuth(), user = _a.user, logout = _a.logout;
    var _b = user, jwt = _b.jwt, id = _b.id;
    var _c = useState(''), retypePassword = _c[0], setRetypePassword = _c[1];
    var _d = useState(''), password = _d[0], setPassword = _d[1];
    var _e = useState(''), passwordError = _e[0], setPasswordError = _e[1];
    var _f = useState({
        loading: false,
        error: null,
    }), apiState = _f[0], setApiState = _f[1];
    useEffect(function () {
        var retypePasswordTrim = retypePassword.trim();
        console.log(retypePasswordTrim);
        var passwordTrim = password.trim();
        if (retypePasswordTrim === '' || passwordTrim === '') {
            setPasswordError('');
            return;
        }
        setPasswordError(retypePasswordTrim === passwordTrim ? '' : '密码输入不一致');
    }, [password, retypePassword]);
    var onSubmit = useCallback(function (evt) {
        evt.preventDefault();
        var _a = evt.target, originalPassword = _a.originalPassword.value, newPassword = _a.password.value;
        var oriPassTrim = originalPassword.trim();
        if (oriPassTrim === '') {
            enqueueSnackbar('原密码不能为空', 'error');
            return;
        }
        var newPassTrim = newPassword.trim();
        if (newPassTrim === '') {
            enqueueSnackbar('新密码不能为空', 'error');
            return;
        }
        // if(newPassTrim !== retypePassword.trim()) {
        //     console.log(newPassTrim, retypePassword);
        //     enqueueSnackbar('两次输入的密码不一致', 'error');
        //     return;
        // }
        if (newPassTrim === oriPassTrim) {
            enqueueSnackbar('新密码不能与原密码相同', 'error');
            return;
        }
        setApiState({ loading: true, error: null });
        var bodyPayload = {
            original_password: oriPassTrim,
            password: newPassTrim,
        };
        ReqJsonToType("/user/".concat(id), {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-JWT': jwt,
            },
            body: JSON.stringify(bodyPayload),
        })
            .then(function (_userInfo) {
            setApiState({ loading: false, error: null });
            // login({...userInfo, jwt});
            enqueueSnackbar("\u5BC6\u7801\u4FEE\u6539\u6210\u529F", 'success');
            logout();
        })
            .catch(function (error) {
            setApiState({ loading: false, error: error });
            enqueueSnackbar(error.message, 'error');
        });
    }, []);
    var theme = useTheme();
    return _jsx(Paper, { children: _jsx("form", { method: "post", onSubmit: onSubmit, children: _jsxs(Stack, { gap: 1, sx: {
                    padding: theme.spacing(2)
                }, children: [_jsx(Divider, { children: "\u4FEE\u6539\u5BC6\u7801" }), _jsx(TextField, { fullWidth: true, disabled: apiState.loading, name: "originalPassword", type: "password", defaultValue: "", label: "\u539F\u5BC6\u7801", required: true }), _jsx(TextField, { fullWidth: true, disabled: apiState.loading, name: "password", type: "password", value: password, onChange: function (evt) { return setPassword(evt.target.value); }, label: "\u65B0\u5BC6\u7801", required: true }), _jsx(TextField, { fullWidth: true, disabled: apiState.loading, name: "retypePassword", type: "password", value: retypePassword, onChange: function (evt) { return setRetypePassword(evt.target.value); }, label: "\u786E\u8BA4\u65B0\u5BC6\u7801", required: true, error: passwordError !== '', helperText: passwordError }), apiState.error && _jsx(AlertSimple, { severity: "error", onClose: function () { return setApiState(function (old) { return (__assign(__assign({}, old), { error: null })); }); }, children: apiState.error.message }), _jsx(LoadingButton, { fullWidth: true, type: "submit", loading: apiState.loading, disabled: passwordError !== '', children: "\u63D0\u4EA4" })] }) }) });
});
