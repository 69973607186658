import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { createContext, useContext, useMemo, useState } from "react";
var AuthContext = createContext({
    user: null,
    login: function () { },
    logout: function () { },
});
export var AuthProvider = function (_a) {
    var children = _a.children;
    var defaultUser = null;
    try {
        var value_1 = window.localStorage.getItem('user');
        if (value_1) {
            defaultUser = JSON.parse(value_1);
        }
    }
    catch (err) {
        console.error(err);
    }
    var _b = useState(defaultUser), user = _b[0], setUser = _b[1];
    // const navigate = useNavigate();
    var login = function (loginUser) {
        setUser(loginUser);
        window.localStorage.setItem('user', JSON.stringify(loginUser));
        // navigate("/");
    };
    var logout = function () {
        window.localStorage.removeItem('user');
        setUser(null);
        // navigate("/login", { replace: true });
    };
    var value = useMemo(function () { return ({
        user: user,
        login: login,
        logout: logout,
    }); }, [user]);
    return _jsx(AuthContext.Provider, { value: value, children: children });
};
export default (function () { return useContext(AuthContext); });
