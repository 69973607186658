// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index__relative--v_OH4 {
    position: relative;
}

.index__overlay--R_lb8 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    /* background-color: #ffffffa1; */
}

/* .verticalPos {
    margin-top: min(50%, 200px);
} */

.index__progress--OWkD0 {
    width: 100%;
    height: 100%;
    max-height: 70vh;

    display: flex;
    align-items: center;
    justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ProgressOverlay/index.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,SAAS;IACT,OAAO;IACP,QAAQ;;IAER,mBAAmB;IACnB,yBAAyB;IACzB,6BAA6B;IAC7B,iCAAiC;AACrC;;AAEA;;GAEG;;AAEH;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;;IAEhB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".relative {\n    position: relative;\n}\n\n.overlay {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n\n    /* display: flex; */\n    /* align-items: center; */\n    /* justify-content: center; */\n    /* background-color: #ffffffa1; */\n}\n\n/* .verticalPos {\n    margin-top: min(50%, 200px);\n} */\n\n.progress {\n    width: 100%;\n    height: 100%;\n    max-height: 70vh;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"relative": `index__relative--v_OH4`,
	"overlay": `index__overlay--R_lb8`,
	"progress": `index__progress--OWkD0`
};
export default ___CSS_LOADER_EXPORT___;
