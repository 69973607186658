import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Context, ThemeType } from "~/Components/Theme/ThemeProvider";
export default (function () {
    var theme = useContext(Context).theme;
    var toastTheme = theme === ThemeType.Light
        ? "light"
        : "dark";
    return _jsx(ToastContainer, { position: "bottom-right", theme: toastTheme, autoClose: 3000, hideProgressBar: false, newestOnTop: false, closeOnClick: true, rtl: false, pauseOnFocusLoss: true, draggable: true, pauseOnHover: true });
});
