// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index__showIfParentHover--WiRaY{visibility:hidden}:hover>.index__showIfParentHover--WiRaY{visibility:visible}
`, "",{"version":3,"sources":["webpack://./src/Pages/Chat/SideDrawer/TopicName/index.scss"],"names":[],"mappings":"AAAA,iCAEI,iBAAkB,CAEpB,wCAEE,kBAAmB","sourcesContent":[".showIfParentHover {\n//   display: none;\n    visibility: hidden;\n\n  :hover > & {\n    // display: 'flex';\n    visibility: visible;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"showIfParentHover": `index__showIfParentHover--WiRaY`
};
export default ___CSS_LOADER_EXPORT___;
