import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
// import Req from "~/Utils/Req";
// import { Menu } from 'antd';
// import Companies, { CompanyIcon } from './Companies';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { useContext, useState } from 'react';
import { Context, ThemeType } from "~/Components/Theme/ThemeProvider";
// import Style from "./index.css";
// import { useTheme } from '@mui/material';
import Style from "./index.css";
import LinearProgress from '@mui/material/LinearProgress';
import DarkLightToggle from './DarkLightToggle';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useAuth from '../Auth/useAuth';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import Button from '@mui/material/Button';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import Groups2TwoToneIcon from '@mui/icons-material/Groups2TwoTone';
export var StyleContentMarginNoTop = Style.contentMarginNoTop;
export var StyleSticky = Style.sticky;
export var TopLinearProgress = function () { return _jsx("div", { className: Style.topLoader, children: _jsx(LinearProgress, {}) }); };
export default (function () {
    var _a = useContext(Context), theme = _a.theme, setTheme = _a.setTheme;
    var navigate = useNavigate();
    var _b = useState(null), anchorElNav = _b[0], setAnchorElNav = _b[1];
    // const curTheme = useTheme();
    var pathname = useLocation().pathname;
    console.log(pathname);
    // const selected = 'profile';
    var handleOpenNavMenu = function (event) {
        setAnchorElNav(event.currentTarget);
    };
    var handleCloseNavMenu = function () {
        setAnchorElNav(null);
    };
    var user = useAuth().user;
    var isAdmin = user.is_admin;
    console.log(user);
    return _jsxs(_Fragment, { children: [_jsx(AppBar, { position: "sticky", children: _jsx(Container, { maxWidth: "md", children: _jsxs(Toolbar, { disableGutters: true, children: [_jsxs(Box, { sx: { flexGrow: 1, display: { xs: 'flex', md: 'none' } }, children: [_jsx(IconButton, { size: "large", "aria-label": "account of current user", "aria-controls": "menu-appbar", "aria-haspopup": "true", onClick: handleOpenNavMenu, color: "inherit", children: _jsx(MenuIcon, {}) }), _jsxs(Menu, { anchorEl: anchorElNav, anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }, keepMounted: true, transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }, open: Boolean(anchorElNav), onClose: handleCloseNavMenu, sx: {
                                            display: { xs: 'block', md: 'none' },
                                        }, children: [_jsxs(MenuItem, { component: Link, to: "/", children: [_jsx(ListItemIcon, { children: _jsx(ArrowBackIosNewTwoToneIcon, { fontSize: 'small' }) }), _jsx(ListItemText, { children: "\u8FD4\u56DE" })] }), _jsxs(MenuItem, { component: Link, to: "/dashboard", selected: pathname === '/dashboard', onClick: handleCloseNavMenu, children: [_jsx(ListItemIcon, { children: _jsx(AccountCircleTwoToneIcon, { fontSize: 'small' }) }), _jsx(ListItemText, { children: "\u4E2A\u4EBA\u8D44\u6599" })] }), isAdmin && _jsxs(MenuItem, { component: Link, to: "/dashboard/user", selected: pathname === '/dashboard/user', onClick: handleCloseNavMenu, children: [_jsx(ListItemIcon, { children: _jsx(Groups2TwoToneIcon, { fontSize: 'small' }) }), _jsx(ListItemText, { children: "\u7528\u6237\u7BA1\u7406" })] })] })] }), _jsxs(Box, { sx: { flexGrow: 1, display: { xs: 'none', md: 'flex' } }, children: [_jsx(Button, { startIcon: _jsx(ArrowBackIosNewTwoToneIcon, {}), component: Link, to: "/", children: "\u8FD4\u56DE" }), _jsxs(Tabs, { value: pathname, indicatorColor: "secondary", textColor: "inherit", onChange: function (event, newValue) {
                                            console.log(newValue);
                                            navigate(newValue);
                                        }, children: [_jsx(Tab, { label: "\u4E2A\u4EBA\u8D44\u6599", value: "/dashboard" }), isAdmin && [
                                                _jsx(Tab, { label: "\u7528\u6237\u7BA1\u7406", value: "/dashboard/user" }, "/dashboard/user")
                                            ]] })] }), _jsxs(Box, { sx: { flexGrow: 0 }, children: [_jsx(DarkLightToggle, { isDark: theme === ThemeType.Dark, onChange: function (toDark) { return setTheme(toDark ? ThemeType.Dark : ThemeType.Light); } }), _jsx(IconButton, { size: "small", component: Link, to: "/logout", sx: { display: { xs: 'inline-flex', md: 'none' } }, children: _jsx(LogoutTwoToneIcon, {}) }), _jsx(Button, { startIcon: _jsx(LogoutTwoToneIcon, {}), component: Link, to: "/logout", sx: { display: { xs: 'none', md: 'inline-flex' } }, children: "\u767B\u51FA" })] })] }) }) }), _jsx(Container, { maxWidth: "md", children: _jsx(Outlet, {}) })] });
});
