import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import useTheme from "@mui/material/styles/useTheme";
import SendIcon from '@mui/icons-material/Send';
import Style from './index.scss';
import useAuth from "~/Components/Auth/useAuth";
import Select from "@mui/material/Select";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import LoadingButton from "@mui/lab/LoadingButton";
import apiOptions from "~/Utils/ApiOptions";
var parseSelectValue = function (value) {
    var _a = value.split("|"), indexStr = _a[0], model = _a[1];
    return {
        pick: parseInt(indexStr, 10),
        model: model,
    };
};
var ITEM_HEIGHT = 48;
var ITEM_PADDING_TOP = 8;
var MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * apiOptions.map(function (each) { return each.models.length; }).reduce(function (a, b) { return a + b; }, 0) + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export default (function (_a) {
    var apiPicked = _a.apiPicked, setApiPicked = _a.setApiPicked, inputStr = _a.inputStr, setInputStr = _a.setInputStr, onEnter = _a.onEnter, loading = _a.loading, _b = _a.inputDisabled, inputDisabled = _b === void 0 ? false : _b;
    var user = useAuth().user;
    var apiPick = user.api_pick;
    var theme = useTheme();
    return _jsxs(Paper, { className: Style.inputContainer, sx: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
            // gap: theme.spacing(1),
            alignItems: 'flex-start'
        }, children: [apiPick === 0 && _jsx(Select, { value: "".concat(apiPicked.pick, "|").concat(apiPicked.model), onChange: function (_a) {
                    var value = _a.target.value;
                    return setApiPicked(parseSelectValue(value));
                }, input: _jsx(InputBase, {}), renderValue: function (selected) {
                    var selectedApiPick = parseSelectValue(selected);
                    var selectedOption = apiOptions.find(function (each) { return each.pick === selectedApiPick.pick; });
                    return (_jsx(Avatar, { sx: {
                            width: 20,
                            height: 20,
                            // bgcolor: ''
                            bgcolor: selectedOption.color,
                        }, children: selectedOption.name[0] }));
                }, MenuProps: MenuProps, children: apiOptions.map(function (_a) {
                    var pick = _a.pick, name = _a.name, color = _a.color, models = _a.models;
                    return models.map(function (model) { return _jsxs(MenuItem, { value: "".concat(pick, "|").concat(model), children: [_jsx(SmartToyTwoToneIcon, { sx: { marginRight: 1, color: color } }), name[0], ": ", model] }, "".concat(pick, "|").concat(model)); });
                }) }), _jsx(TextField, { value: inputStr, variant: "standard", multiline: true, fullWidth: true, placeholder: "Ctrl+Enter \u6362\u884C", onKeyDown: function (e) {
                    var ctrlKey = e.ctrlKey, key = e.key;
                    if (key === 'Enter') {
                        e.preventDefault();
                        if (ctrlKey) {
                            console.log('break line');
                            setInputStr(function (oldValue) { return oldValue + "\n"; });
                        }
                        else {
                            onEnter();
                        }
                    }
                }, onChange: function (_a) {
                    var value = _a.target.value;
                    return setInputStr(value);
                }, disabled: inputDisabled, helperText: apiPick === 0
                    ? apiPicked.model
                    : undefined }), _jsx(LoadingButton, { disabled: inputStr.trim() === '' || inputDisabled, loading: loading, onClick: onEnter, children: _jsx(SendIcon, {}) })] });
});
