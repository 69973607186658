import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import ReplayIcon from '@mui/icons-material/Replay';
export default (function (_a) {
    var severity = _a.severity, onReload = _a.onReload, onClose = _a.onClose, children = _a.children;
    return _jsx(Alert, { severity: severity, onClose: onClose, action: onReload
            ? _jsx(Button, { color: "inherit", size: "small", onClick: onReload, children: _jsx(ReplayIcon, {}) })
            : undefined, children: children });
});
