// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index__card--Hyq95{max-width:80%;min-width:40%}.index__messageContent--JPZ81 p:first-child{margin-top:0}.index__messageContent--JPZ81 p:last-child{margin-bottom:0}
`, "",{"version":3,"sources":["webpack://./src/Pages/Chat/MessageCardBase/index.scss"],"names":[],"mappings":"AAAA,oBACI,aAAc,CACd,aAAc,CACjB,4CAIO,YAAa,CAHrB,2CAQQ,eAAgB","sourcesContent":[".card {\n    max-width: 80%;\n    min-width: 40%;\n}\n.messageContent {\n    p:first-child {\n        // background: red;\n        margin-top: 0;\n    }\n\n    p:last-child {\n        // background: blue;\n        margin-bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `index__card--Hyq95`,
	"messageContent": `index__messageContent--JPZ81`
};
export default ___CSS_LOADER_EXPORT___;
