//
import { toast } from 'react-toastify';
// interface Params {
//     message: string | JSX.Element,
//     severity: 'success' | 'error'
// }
export default (function (message, severity) {
    if (severity === undefined) {
        toast(message);
    }
    else {
        toast[severity](message);
    }
});
