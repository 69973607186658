import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import TextField from "@mui/material/TextField";
import { useCallback, useEffect, useRef, useState } from "react";
import useAuth from "~/Components/Auth/useAuth";
import { ReqJsonToType } from "~/Utils/Req";
import enqueueSnackbar from "~/Utils/EnqueueSnackbar";
import useAbortController from "~/Utils/useAbortController";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import { ReloadingIcon } from "~/Components/RotateClass";
export default (function (_a) {
    var topicId = _a.id, defaultValue = _a.defaultValue, onChanged = _a.onChanged, onCancel = _a.onCancel;
    var _b = useState(defaultValue), inputValue = _b[0], setInputValue = _b[1];
    var inputRef = useRef();
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var getAbortSignal = useAbortController();
    var user = useAuth().user;
    var jwt = user.jwt;
    useEffect(function () {
        if (inputRef.current) {
            inputRef.current.select();
        }
    }, [
        inputRef.current,
    ]);
    var onSubmit = useCallback(function () {
        var trimedInputValue = inputValue.trim();
        if (trimedInputValue === '') {
            enqueueSnackbar('标题不能为空', 'error');
            return;
        }
        setLoading(true);
        ReqJsonToType("/topic/".concat(topicId), {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-JWT': jwt,
            },
            body: JSON.stringify({ topic: trimedInputValue }),
            signal: getAbortSignal(),
        })
            .then(function (_a) {
            var topic = _a.topic;
            setLoading(false);
            setInputValue(topic);
            onChanged(topic);
            enqueueSnackbar("\u4FEE\u6539\u6210\u529F: ".concat(topic), 'success');
        })
            .catch(function (error) {
            setLoading(false);
            enqueueSnackbar(error.message, 'error');
        });
    }, [inputValue]);
    return _jsx(TextField, { fullWidth: true, inputRef: inputRef, value: inputValue, 
        // onBlur={() => {
        //     setRename(false);
        // }}
        onKeyDown: function (e) {
            if (e.key === 'Enter') {
                // setRename(false);
                onSubmit();
            }
        }, onChange: function (e) { setInputValue(e.target.value); }, InputProps: {
            endAdornment: (loading
                ? _jsx(ReloadingIcon, {})
                : _jsxs(_Fragment, { children: [_jsx(IconButton, { onClick: onCancel, size: "small", children: _jsx(CloseIcon, { fontSize: "small" }) }), _jsx(IconButton, { onClick: onSubmit, size: "small", children: _jsx(CheckIcon, { fontSize: "small" }) })] }))
        } });
});
