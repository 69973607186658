// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index__rotateBase--kPjOX {
    transition:0.5s;
}

.index__rotate90--PKLET {
    transform:rotate(90deg);
}

@-webkit-keyframes index__rotating--CIMlF /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(-360deg);
      -o-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
  @keyframes index__rotating--CIMlF {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(-360deg);
      -moz-transform: rotate(-360deg);
      -webkit-transform: rotate(-360deg);
      -o-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }

.index__rotateLoopAnimationBaseTime--ASZiE {
    animation: index__rotating--CIMlF 0.5s linear infinite;
}`, "",{"version":3,"sources":["webpack://./src/Components/RotateClass/index.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI;MACE,+BAA+B;MAC/B,0BAA0B;MAC1B,uBAAuB;IACzB;IACA;MACE,kCAAkC;MAClC,6BAA6B;MAC7B,0BAA0B;IAC5B;EACF;EACA;IACE;MACE,2BAA2B;MAC3B,4BAA4B;MAC5B,+BAA+B;MAC/B,0BAA0B;MAC1B,uBAAuB;IACzB;IACA;MACE,8BAA8B;MAC9B,+BAA+B;MAC/B,kCAAkC;MAClC,6BAA6B;MAC7B,0BAA0B;IAC5B;EACF;;AAEF;IACI,sDAAwC;AAC5C","sourcesContent":[".rotateBase {\n    transition:0.5s;\n}\n\n.rotate90 {\n    transform:rotate(90deg);\n}\n\n@-webkit-keyframes rotating /* Safari and Chrome */ {\n    from {\n      -webkit-transform: rotate(0deg);\n      -o-transform: rotate(0deg);\n      transform: rotate(0deg);\n    }\n    to {\n      -webkit-transform: rotate(-360deg);\n      -o-transform: rotate(-360deg);\n      transform: rotate(-360deg);\n    }\n  }\n  @keyframes rotating {\n    from {\n      -ms-transform: rotate(0deg);\n      -moz-transform: rotate(0deg);\n      -webkit-transform: rotate(0deg);\n      -o-transform: rotate(0deg);\n      transform: rotate(0deg);\n    }\n    to {\n      -ms-transform: rotate(-360deg);\n      -moz-transform: rotate(-360deg);\n      -webkit-transform: rotate(-360deg);\n      -o-transform: rotate(-360deg);\n      transform: rotate(-360deg);\n    }\n  }\n\n.rotateLoopAnimationBaseTime {\n    animation: rotating 0.5s linear infinite;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rotateBase": `index__rotateBase--kPjOX`,
	"rotate90": `index__rotate90--PKLET`,
	"rotateLoopAnimationBaseTime": `index__rotateLoopAnimationBaseTime--ASZiE`,
	"rotating": `index__rotating--CIMlF`
};
export default ___CSS_LOADER_EXPORT___;
