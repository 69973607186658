var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import Container from "@mui/material/Container";
import { useCallback, useEffect, useState } from "react";
import ProcessingMessageCard from "../ProcessingMessageCard";
import { ReqJsonToType } from "~/Utils/Req";
import useAuth from "~/Components/Auth/useAuth";
import RetryErrorSuspense from "~/Components/RetryErrorSuspense";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import CardStyle from '../MessageCardBase/index.scss';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import useFetch from "~/Utils/useFetch";
import AlertSimple from "~/Components/AlertSimple";
import ChatInput from "../ChatInput";
import { RandomRange } from "~/Utils/Random";
import ReadyChat from "./ReadyChat";
import useApi from "~/Components/useApi";
import useTheme from "@mui/material/styles/useTheme";
import Paper from "@mui/material/Paper";
var WaitInitMessage = function (_a) {
    var _b = _a.topic, id = _b.id, systemPreload = _b.system_preload, userInit = _b.user_init, extractType = _b.extract_type, setInitResponseMessages = _a.setInitResponseMessages;
    var user = useAuth().user;
    var jwt = user.jwt;
    // const [apiPicked, setApiPicked] = useState<number>(apiPick === 0? userInitApi: apiPick);
    var _c = useApi(), apiPicked = _c.apiPick, setApiPicked = _c.setApiPick;
    var apiBody = {
        api: apiPicked,
    };
    var _d = useFetch("/chat/".concat(id), [], {
        method: 'POST',
        body: JSON.stringify(apiBody),
        headers: {
            'Content-Type': 'application/json',
            'X-JWT': jwt,
        },
    }, function (resp) { return resp.json().then(function (messages) { return messages; }); }), loading = _d.loading, error = _d.error, serverMessages = _d.data, reloadCallback = _d.reloadCallback;
    useEffect(function () {
        if (serverMessages.length > 0) {
            console.log("init response:", serverMessages);
            setInitResponseMessages(serverMessages);
        }
    }, [
        loading,
        error,
        serverMessages,
    ]);
    var theme = useTheme();
    return _jsxs(Paper, { sx: {
            gap: theme.spacing(1),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
        }, children: [(systemPreload !== "" || extractType != "") && _jsx(Card, { variant: "outlined", children: _jsxs(CardContent, { className: CardStyle.messageContent, children: [systemPreload !== "" && _jsx("pre", { style: {
                                whiteSpace: 'pre-wrap',
                            }, children: systemPreload }), extractType != "" && _jsx(Chip, { label: extractType })] }) }), userInit !== ""
                ? _jsx(ProcessingMessageCard, { role: "user", stripped_content: userInit, canCancel: false, onCancel: function () { }, onRetry: reloadCallback, loading: loading, error: error, id: RandomRange(-1, -99999) })
                : _jsxs(_Fragment, { children: [loading && _jsx(CircularProgress, { color: "secondary" }), error && _jsx(AlertSimple, { severity: "error", onReload: reloadCallback, children: error.message })] }), _jsx(ChatInput, { apiPicked: apiPicked, setApiPicked: setApiPicked, inputStr: "", setInputStr: function () { }, onEnter: function () { }, loading: loading, inputDisabled: true })] });
};
var ServerMessagesRenderer = function (_a) {
    var getTopicInfo = _a.getResource;
    var _b = getTopicInfo(), topic = _b.topic, messages = _b.messages;
    useEffect(function () {
        // if(scrolled)  {
        //     return;
        // }
        // setScrolled(true);
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, []);
    // useEffect(() => {
    //     console.log(`run once!`);
    // }, [])
    var _c = useState([]), initResponseMessages = _c[0], setInitResponseMessages = _c[1];
    var allMessages = __spreadArray(__spreadArray([], initResponseMessages, true), messages, true);
    return allMessages.length === 0
        ? _jsx(WaitInitMessage, { topic: topic, setInitResponseMessages: setInitResponseMessages })
        : _jsx(ReadyChat, { topic: topic, initMessages: allMessages });
    // return <>
    //     {(systemPreload !== "" || tooltipConfigs.length > 0) && <Card variant="outlined">
    //         <CardContent className={CardStyle.messageContent}>
    //             {/* {systemPreload !== "" && <ReactMarkdown>{systemPreload}</ReactMarkdown>} */}
    //             {systemPreload !== "" && <pre style={{
    //                 whiteSpace: 'pre-wrap',
    //             }}>{systemPreload}</pre>}
    //             {tooltipConfigs.length > 0 && <Stack direction="row" gap={1}>
    //                 {tooltipConfigs.map(({id, start, end}, index) => <Chip key={index} label={`${start}${id}${end}`} />)}
    //             </Stack>}
    //         </CardContent>
    //     </Card>}
    //     {messages.map((message) => <MessageCard
    //         key={message.id}
    //         nickName={chatNickName}
    //         avatar={chatAvatar}
    //         {...message} />)}
    // </>;
};
export default (function (_a) {
    var topicId = _a.topicId;
    var user = useAuth().user;
    var jwt = user.jwt;
    // const { state: { firstChat: defaultFirstChat=""}} = useLocation();
    // const [firstChat, setFirstChat] = useState<string>(defaultFirstChat);
    // const cleanFirstChat = useCallback(() => {
    //     window.history.replaceState({}, '');
    //     setFirstChat('');
    // }, []);
    var makePromise = useCallback(function (abortController) {
        return ReqJsonToType("/topic/".concat(topicId), {
            headers: {
                'X-JWT': jwt,
            },
            signal: abortController.signal
        });
    }, [topicId]);
    // const [scrolled, setScrolled] = useState<boolean>(false);
    return _jsx(Container, { maxWidth: "md", children: _jsx(RetryErrorSuspense, { noTrace: true, makePromise: makePromise, fallback: _jsx(Box, { sx: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80vh',
                }, children: _jsx(CircularProgress, { color: "secondary", size: 100 }) }), renderer: function (renderProps) { return _jsx(ServerMessagesRenderer, __assign({}, renderProps)); } }) });
});
