import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { createContext, useContext, useMemo, useState } from "react";
// import useAuth from "../Auth/useAuth";
import apiOptions from "~/Utils/ApiOptions";
// 非机密信息，直接写在这里，不做远程调用了
var DefaultApiPick = {
    pick: apiOptions[0].pick,
    model: apiOptions[0].models[0]
};
var ApiPickContext = createContext({
    apiPick: DefaultApiPick,
    setApiPick: function () { },
});
export var ApiPickProvider = function (_a) {
    // const { user } = useAuth();
    // const { api_pick: apiPickPermission } = user as User;
    var children = _a.children;
    var _b = useState(function () {
        try {
            var value_1 = window.localStorage.getItem('apiPick');
            if (value_1) {
                return JSON.parse(value_1);
            }
        }
        catch (err) {
            console.error(err);
        }
        return DefaultApiPick;
    }), apiPickState = _b[0], setApiPickState = _b[1];
    var setApiPick = function (apiPick) {
        setApiPickState(apiPick);
        window.localStorage.setItem('apiPick', JSON.stringify(apiPick));
    };
    var value = useMemo(function () { return ({
        apiPick: apiPickState,
        setApiPick: setApiPick,
    }); }, [apiPickState]);
    return _jsx(ApiPickContext.Provider, { value: value, children: children });
};
export default (function () { return useContext(ApiPickContext); });
