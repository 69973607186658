import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useContext } from 'react';
import { Context, ThemeType } from "~/Components/Theme/ThemeProvider";
import DarkLightToggle from '~/Components/Layout/DarkLightToggle';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
export default (function () {
    var _a = useContext(Context), theme = _a.theme, setTheme = _a.setTheme;
    return _jsxs(ListItemButton, { onClick: function () { return setTheme(theme === ThemeType.Dark ? ThemeType.Light : ThemeType.Dark); }, children: [_jsx(ListItemIcon, { children: _jsx(DarkLightToggle, { isDark: theme === ThemeType.Dark, onChange: function (toDark) { return setTheme(toDark ? ThemeType.Dark : ThemeType.Light); } }) }), _jsx(ListItemText, { primary: theme === ThemeType.Light ? "浅色" : "深色" })] });
});
