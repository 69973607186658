var allOptions = [{
        name: "无",
        value: ""
    }, {
        name: "{内容}",
        value: "{}"
    }, {
        name: "JSON",
        value: "JSON"
    }];
export default allOptions;
