var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import useAuth from "~/Components/Auth/useAuth";
import { ReqJsonToType } from "~/Utils/Req";
import useTheme from "@mui/material/styles/useTheme";
import enqueueSnackbar from "~/Utils/EnqueueSnackbar";
import { RandomRange } from "~/Utils/Random";
import Paper from "@mui/material/Paper";
import MessageCard from "../MessageCard";
import ChatInput from "../ChatInput";
import ProcessingMessageCard from "../ProcessingMessageCard";
import Chip from "@mui/material/Chip";
import useApi from "~/Components/useApi";
export default (function (_a) {
    var topic = _a.topic, initMessages = _a.initMessages;
    var _b = useState(""), inputStr = _b[0], setInputStr = _b[1];
    var _c = useState(initMessages), messages = _c[0], setMessages = _c[1];
    var _d = useState(null), processingMessage = _d[0], setProcessingMessage = _d[1];
    var user = useAuth().user;
    var jwt = user.jwt;
    var _e = useApi(), savedApiPick = _e.apiPick, saveApiPick = _e.setApiPick;
    var _f = useState(false), unfoldAll = _f[0], setUnfoldAll = _f[1];
    var sendUserMessage = function (message) {
        setProcessingMessage(__assign(__assign({}, message), { loading: true, error: null }));
        var apiPayload = {
            topic: topic,
            message: message,
            api: savedApiPick,
        };
        ReqJsonToType("/chat", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-JWT': jwt,
            },
            body: JSON.stringify(apiPayload),
        })
            .then(function (messages) {
            console.log("Received", messages);
            setMessages(function (oldMessages) { return __spreadArray(__spreadArray([], oldMessages, true), messages, true); });
            setProcessingMessage(null);
        })
            .catch(function (error) {
            console.log(error);
            setProcessingMessage((__assign(__assign({}, message), { loading: false, error: error })));
        });
    };
    useEffect(function () {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, [messages]);
    var theme = useTheme();
    var sendMessage = useCallback(function () {
        if (inputStr.trim() === '') {
            enqueueSnackbar('输入为空', 'error');
            return;
        }
        if (processingMessage && processingMessage.loading) {
            enqueueSnackbar('请等待处理完毕', 'info');
            return;
        }
        console.log("send: ".concat(inputStr));
        sendUserMessage({
            role: "user",
            stripped_content: inputStr,
            id: RandomRange(-1, -99999),
        });
        setInputStr('');
    }, [inputStr, processingMessage]);
    return _jsxs(_Fragment, { children: [_jsxs(Paper, { sx: {
                    gap: theme.spacing(1),
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                    padding: theme.spacing(2),
                    display: 'flex',
                    flexDirection: 'column',
                }, children: [topic.extract_type !== "" && _jsx(Chip, { label: topic.extract_type }), messages.map(function (message) {
                        var _a, _b;
                        var isUser = message.role === 'user';
                        var nickName = (_a = (isUser ? '你' : topic.name)) !== null && _a !== void 0 ? _a : '';
                        var avatar = (_b = (isUser ? '' : topic.avatar)) !== null && _b !== void 0 ? _b : '';
                        return _jsx(MessageCard, __assign({ nickName: nickName, avatar: avatar, unfoldAll: unfoldAll, setUnfoldAll: setUnfoldAll }, message), message.id);
                    }), processingMessage && _jsx(ProcessingMessageCard, __assign({}, processingMessage, { canCancel: false, onCancel: function () { return setProcessingMessage(null); }, onRetry: function () { return sendUserMessage(__assign(__assign({}, processingMessage), { id: RandomRange(-1, -99999) })); } }))] }), _jsx(ChatInput, { apiPicked: savedApiPick, setApiPicked: saveApiPick, inputStr: inputStr, setInputStr: setInputStr, onEnter: sendMessage, loading: (processingMessage === null || processingMessage === void 0 ? void 0 : processingMessage.loading) || false })] });
});
