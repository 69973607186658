var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import LoadingButton from '@mui/lab/LoadingButton';
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import useTheme from "@mui/material/styles/useTheme";
import SendIcon from '@mui/icons-material/Send';
import { useCallback, useState } from "react";
import Style from './index.scss';
import { ReqJsonToType } from "~/Utils/Req";
import enqueueSnackbar from '~/Utils/EnqueueSnackbar';
import AlertSimple from '~/Components/AlertSimple';
import Box from '@mui/material/Box';
import useAuth from '~/Components/Auth/useAuth';
import { useNavigate } from 'react-router-dom';
export default (function () {
    var theme = useTheme();
    var navigate = useNavigate();
    var login = useAuth().login;
    var _a = useState({
        loading: false,
        error: null
    }), apiState = _a[0], setApiState = _a[1];
    var onFormSubmit = useCallback(function (evt) {
        evt.preventDefault();
        if (apiState.loading) {
            return false;
        }
        var target = evt.target;
        var account = target.account.value.trim();
        var password = target.password.value.trim();
        if (account === "") {
            setApiState({ loading: false, error: new Error("用户名不能为空") });
            return false;
        }
        if (password === "") {
            setApiState({ loading: false, error: new Error("密码不能为空") });
            return false;
        }
        var apiPayload = {
            account: account,
            password: password,
        };
        setApiState({ loading: true, error: null });
        ReqJsonToType("/login", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(apiPayload),
        })
            .then(function (res) {
            setApiState({ loading: false, error: null });
            enqueueSnackbar("登录成功", "success");
            console.log("login user", res);
            login(res);
            navigate('/');
        })
            .catch(function (error) {
            setApiState({ loading: false, error: error });
            enqueueSnackbar(error.message, 'error');
        });
        return false;
    }, []);
    return _jsx(_Fragment, { children: _jsx(Container, { maxWidth: "md", children: _jsxs(Paper, { className: Style.displayPaper, sx: {
                    gap: theme.spacing(1),
                    marginTop: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                    padding: theme.spacing(2),
                }, children: [_jsxs("form", { method: 'POST', onSubmit: onFormSubmit, children: [_jsx(TextField, { disabled: apiState.loading, label: "\u7528\u6237\u540D", name: "account", variant: "outlined", fullWidth: true, margin: "normal", required: true }), _jsx(TextField, { disabled: apiState.loading, label: "\u5BC6\u7801", name: "password", type: "password", variant: "outlined", fullWidth: true, margin: "normal", required: true }), _jsx(LoadingButton, { loading: apiState.loading, type: "submit", variant: "contained", color: "primary", fullWidth: true, startIcon: _jsx(SendIcon, {}), children: "\u767B\u5F55" })] }), apiState.error && _jsx(Box, { sx: {
                            paddingTop: theme.spacing(2),
                        }, children: _jsx(AlertSimple, { severity: "error", onClose: function () { return setApiState(function (oldValue) { return (__assign(__assign({}, oldValue), { error: null })); }); }, children: apiState.error.message }) })] }) }) });
});
