import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import Button from "@mui/material/Button";
import useTheme from "@mui/material/styles/useTheme";
import { useMemo, useRef, useState } from "react";
import SideDrawer from "./SideDrawer";
import MenuIcon from '@mui/icons-material/Menu';
import ChatArea from "./ChatArea";
import { useParams } from "react-router-dom";
import NewTopic from "./NewTopic";
export default (function () {
    var topicIdRaw = useParams().topicId;
    var topicId = topicIdRaw === undefined ? null : parseInt(topicIdRaw);
    var sideDrawerRef = useRef(null);
    var theme = useTheme();
    var isNarrowScreen = useMemo(function () {
        // console.log(theme.breakpoints.values.md - parseInt(theme.spacing(28).replace('px', ''), 10));
        // console.log(window.innerWidth);
        return window.innerWidth < theme.breakpoints.values.md - parseInt(theme.spacing(28).replace('px', ''), 10);
    }, []);
    // console.log(`isNarrowScreen=${isNarrowScreen}`);
    var _a = useState(!isNarrowScreen), open = _a[0], setOpen = _a[1];
    // console.log(messages.length);
    return _jsxs(_Fragment, { children: [_jsx(SideDrawer, { ref: sideDrawerRef, autoHide: isNarrowScreen, topicId: topicId, open: open, setClose: function () { return setOpen(false); } }), _jsx(Button, { variant: "contained", color: "inherit", onClick: function () { return setOpen(true); }, sx: {
                    position: 'sticky',
                    top: theme.spacing(2),
                    left: theme.spacing(2),
                    zIndex: 100,
                }, children: _jsx(MenuIcon, {}) }), topicId === null
                ? _jsx(NewTopic, { onAddTopic: function (topic) {
                        var _a;
                        (_a = sideDrawerRef.current) === null || _a === void 0 ? void 0 : _a.addTopic(topic);
                    } })
                : _jsx(ChatArea, { topicId: topicId })] });
});
