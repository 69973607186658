import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import ExtractOptions from "~/Utils/ExtractOptions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
export default (function (_a) {
    var extractOption = _a.extractOption, setExtractOption = _a.setExtractOption;
    return _jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { children: "\u5185\u5D4C\u8BC6\u522B" }), _jsx(Select, { value: extractOption.value, label: "\u5185\u5D4C\u8BC6\u522B", onChange: function (_a) {
                    var value = _a.target.value;
                    var extractValue = value;
                    for (var _i = 0, ExtractOptions_1 = ExtractOptions; _i < ExtractOptions_1.length; _i++) {
                        var option = ExtractOptions_1[_i];
                        if (option.value === extractValue) {
                            setExtractOption(option);
                            return;
                        }
                    }
                    // return setExtractOption(e.target.value as ExtractOption);
                }, children: ExtractOptions.map(function (_a) {
                    var value = _a.value, name = _a.name;
                    return _jsx(MenuItem, { value: value, children: name }, value);
                }) })] });
});
