import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import useAuth from "~/Components/Auth/useAuth";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import AlertSimple from "~/Components/AlertSimple";
import useAbortController from "~/Utils/useAbortController";
import Req from "~/Utils/Req";
import enqueueSnackbar from "~/Utils/EnqueueSnackbar";
var style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    outline: 0,
};
export default (function (_a) {
    var onCloseMenu = _a.onCloseMenu, onDeleted = _a.onDeleted, id = _a.id, topic = _a.topic;
    var loginUser = useAuth().user;
    var jwt = loginUser.jwt;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState(null), error = _d[0], setError = _d[1];
    var getAbortSignal = useAbortController();
    var onDelete = function () {
        setLoading(true);
        setError(null);
        Req("/topic/".concat(id), {
            method: 'DELETE',
            headers: {
                'X-JWT': jwt,
            },
            signal: getAbortSignal(),
        })
            .then(function () {
            setLoading(false);
            setOpen(false);
            onCloseMenu();
            onDeleted();
            enqueueSnackbar("\u5DF2\u5220\u9664\u804A\u5929 ".concat(topic), 'success');
        })
            .catch(function (error) {
            setLoading(false);
            setError(error);
            enqueueSnackbar("\u5220\u9664\u804A\u5929 ".concat(topic, " \u5931\u8D25"), 'error');
        });
    };
    return _jsxs(_Fragment, { children: [_jsxs(MenuItem, { onClick: function () {
                    // onCloseMenu();
                    setOpen(true);
                }, children: [_jsx(ListItemIcon, { children: _jsx(DeleteTwoToneIcon, { color: "error" }) }), _jsx(ListItemText, { children: "\u5220\u9664" })] }), _jsx(Modal, { open: open, onClose: function () {
                    setOpen(false);
                    onCloseMenu();
                }, closeAfterTransition: true, slots: { backdrop: Backdrop }, slotProps: {
                    backdrop: {
                        timeout: 500,
                    },
                }, children: _jsx(Fade, { in: open, children: _jsxs(Box, { sx: style, children: [_jsxs(Stack, { gap: 1, children: [_jsx(Typography, { variant: "h6", children: "\u5220\u9664\u804A\u5929?" }), _jsx(Divider, {}), _jsxs(Typography, { children: ["\u5C06\u5220\u9664\u804A\u5929\u5BF9\u8BDD ", topic] }), error && _jsx(AlertSimple, { severity: "error", onClose: function () { return setError(null); }, children: error.message })] }), _jsxs(Stack, { direction: "row", sx: { justifyContent: 'flex-end' }, children: [_jsx(Button, { color: "inherit", onClick: function () {
                                            onCloseMenu();
                                            setOpen(false);
                                        }, children: "\u53D6\u6D88" }), _jsx(LoadingButton, { color: "error", loading: loading, onClick: onDelete, children: "\u5220\u9664" })] })] }) }) })] });
});
